const Table = () => {
    return (
        <div className="
            flex
            flex-col
            select-none
            m-auto
            mb-0
            mt-24
            rounded-2xl
            dark:bg-darkmode-2
            border-2
            shadow-xl
            ">
            <table>
                <tbody>
                <tr>
                    <th className="border-t-0 border-l-0">L</th>
                    <th className="border-t-0">O</th>
                    <th className="border-t-0">W</th>
                    <th className="border-t-0">A</th>
                    <th className="border-t-0 border-r-0">9</th>
                </tr>
                <tr>
                    <th className="border-l-0">P</th>
                    <th>Y</th>
                    <th>R</th>
                    <th>J</th>
                    <th className="border-r-0">S</th>
                </tr>
                <tr>
                    <th className="border-l-0">2</th>
                    <th>X</th>
                    <th>K</th>
                    <th>Ð</th>
                    <th className="border-r-0">2</th>
                </tr>
                <tr>
                    <th className="border-l-0">F</th>
                    <th>5</th>
                    <th>A</th>
                    <th>I</th>
                    <th className="border-r-0">:</th>
                </tr>
                <tr>
                    <th className="border-b-0 border-l-0">2</th>
                    <th className="border-b-0" >1</th>
                    <th className="border-b-0" >A</th>
                    <th className="border-b-0" >S</th>
                    <th className="border-b-0 border-r-0" >L</th>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table;
