const NewPage = () => {
    return (
        <div className="flex flex-col h-screen">
            <iframe
                className="flex m-auto mt-20 w-1/2 h-1/2"
                src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?controls=0&autoplay=1&rel=0&disablekb=1"
                title="Ricky"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
                </iframe>
        </div>
    );
};

export default NewPage;