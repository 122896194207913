import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";


export default function NewForm() {
    let navigate = useNavigate();
    const [value, setValue] = useState('');

    const handleChange = (e) => setValue(e.target.value);
    const handleSubmit = (e) => {
        e.preventDefault()
        var svar = value.toUpperCase()
        if (svar === 'WJ2AS92F125APXAS') {
            console.log("Rétt!! \"" + svar + "\"") //Hættu að svindla 😢
            navigate("Ricky");
        }
        setValue('')
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-row m-auto mt-4 space-x-2 bg-transparent">
            <input className="input-text" type="text" value={value} onChange={handleChange} />
            <input type="submit" value="Enter" className="input-button" />
        </form>
    );

}