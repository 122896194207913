import { FaSun, FaMoon } from 'react-icons/fa';
import useDarkMode from '../hooks/useDarkMode';


const SideBar = () => {
    return (
        <div className="sticky top-0 left-0 h-16 w-screen md:w-auto m-0
                        flex flex-row
                        text-gray-900
                        bg-white
                        dark:bg-darkmode-2
                        dark:text-white
                        shadow-lg
                        ">
            <a className="
            h-12
            w-auto
            px-4
            mt-2
            mb-2
            ml-4
            rounded-xl
            flex
            items-center
            justify-center
            text-2xl
            font-bold   
            float-left
            tracking-wider
            "
            href="/"
            >
                OliK Website</a>
            <ThemeIcon />

        </div>
    );
};

const ThemeIcon = () => {
    const [darkTheme, setDarkTheme] = useDarkMode();
    const handleMode = () => setDarkTheme(!darkTheme);
    return (
        <div onClick={handleMode} className="sidebar-icon group">
            {darkTheme ? (
                <FaSun size="20" />) : (<FaMoon size="20" />
            )}
        </div>
    );
};

export default SideBar;