import './App.css';
import Sidebar from './Containers/SideBar'
import React from 'react';
import firebase from "firebase/compat/app";
import NewPage from './Containers/NewPage';
import Home from './Containers/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";

firebase.initializeApp({
  apiKey: "AIzaSyAucSwZXLXeMt-ReUcdsmVA5ovpPdw8HgE",
  authDomain: "tailwindwebsiteolik.firebaseapp.com",
  projectId: "tailwindwebsiteolik",
  storageBucket: "tailwindwebsiteolik.appspot.com",
  messagingSenderId: "646828512052",
  appId: "1:646828512052:web:8df182924042a3d4298b61"
})

function App() {
  return (
    <div>
      <Sidebar />
      <BrowserRouter >
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="Ricky" element ={<NewPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default (App);

