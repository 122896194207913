import '../App.css';
import React from 'react';
import Table from './Table';
// import NameForm from './InputForm';
import NewForm from './NewForm';

function Home() {
    return (
        <div className="flex flex-col h-screen">
            <Table />
            <NewForm />
        </div>
    );
}

export default (Home);

